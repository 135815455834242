<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
      @moveForm="moveForm($event)"
    ></passContainer>
    <titleForm title="Creando una nueva" strong="Sede"></titleForm>
    <form
      id="branchForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          @moveForm="moveForm($event)"
        ></first>
        <second
          :submitValidate="submitStart"
          :sectionName="sections[1]"
          sectionPosition="1"
          @moveForm="moveForm($event)"
        ></second>
        <third
          :submitValidate="submitStart"
          :sectionName="sections[2]"
          sectionPosition="2"
          submitName="Crear Sede"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></third>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
import { validateFormData } from "@/modules/tools";
import Swal from "sweetalert2";

import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";
import Legal from "@/components/layout/Legal";

import first from "@/views/branch/form/1";
import second from "@/views/branch/form/2";
import third from "@/views/branch/form/3";

export default {
  data() {
    return {
      companyId: "",
      sections: ["Imagen de Portada", "Datos Básicos", "Contacto"],
      activeSection: "",
      submitStart: false,
      showValidate: false
    };
  },
  created() {
    const store = new Storage();
    store.getData("company").then(data => {
      this.companyId = data[0]._id;
    });
    this.activeSection = this.sections[0];
  },
  methods: {
    submit() {
      const branchForm = document.getElementById("branchForm");
      const select = branchForm.getElementsByTagName("select");
      const textarea = branchForm.getElementsByTagName("textarea");
      const input = branchForm.getElementsByTagName("input");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < textarea.length; i++) {
        if (!validateFormData(textarea[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(branchForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);
      let phone;

      Swal.fire({
        icon: "warning",
        title: "Creando sede",
        html:
          "Por favor espere mientras se crea la sede</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      if (formData.get("phone") === "") {
        phone = null;
      } else {
        phone = parseInt(formData.get("phone"));
      }

      const data = {
        description: formData.get("description"),
        state: formData.get("state"),
        city: formData.get("city"),
        address: formData.get("address"),
        company: this.companyId,
        phone: phone,
        ARL: formData.get("ARL")
      };

      store
        .insertData("optima", "branch", [data])
        .then(data => {
          const progressBranch = document.getElementById("progressBranch");
          const banner = formData.get("banner");

          const percentUpdate = (loaded, total, percent) => {
            percent -= 5;
            progressBranch.innerText = percent.toString() + "%";
            progressBranch.style.width = percent.toString() + "%";
          };

          if (banner.name !== "") {
            formData.append("_id", data[0]._id);
            store
              .uploadFile(
                "branch",
                data[0]._id,
                "optima/branch/upload",
                formData,
                percentUpdate
              )
              .then(res => {
                store
                  .downloadData("optima", "file", {
                    where: "branch=" + data[0]._id
                  })
                  .then(() => {
                    store
                      .downloadData("optima", "target", {
                        where: "branch=" + data[0]._id
                      })
                      .then(() => {
                        this.endSubmit(true);
                      });
                  });
              })
              .catch(error => {
                console.log(error);
                this.endSubmit(false);
              });
          } else {
            store
              .downloadData("optima", "file", {
                where: "branch=" + data[0]._id
              })
              .then(() => {
                store
                  .downloadData("optima", "target", {
                    where: "branch=" + data[0]._id
                  })
                  .then(() => {
                    this.endSubmit(true);
                  });
              });
          }
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Sede creada",
          text:
            "Ahora podrá crear usuarios, empleados y documentos para esta sede"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al crear la sede",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    },
    moveForm(event) {
      const xScroll = document.getElementsByClassName("xScroll")[0];
      let postion;

      if (event === "forward") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i + 1;
            break;
          }
        }
      } else if (event === "back") {
        for (let i = 0; i < this.sections.length; i++) {
          if (this.activeSection === this.sections[i]) {
            postion = i - 1;
            break;
          }
        }
      } else {
        postion = event;
      }
      this.activeSection = this.sections[postion];
      xScroll.style.marginLeft = postion * 100 * -1 + "%";
    }
  },
  components: {
    passContainer,
    titleForm,
    Legal,
    first,
    second,
    third
  },
  name: "Branch Create"
};
</script>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>
